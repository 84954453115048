import {
  profileLoading,
  profileError,
  setProfileList,
} from "../Slices/UpdateProfileSlice";
import {
  getProfileService,
  updateProfileService,
} from "./../../Services/UpdateProfile";

export const getProfileAction = (adminToken) => async (dispatch) => {
  dispatch(profileLoading());
  try {
    const response = await getProfileService(adminToken);
    if (response?.error) {
      dispatch(profileError(response.error));
      return;
    } else {
      dispatch(setProfileList(response?.data));
      return;
    }
  } catch (error) {
    dispatch(profileError(error.message));
    return;
  }
};

export const updateProfileAction =
  (formData, adminToken) => async (dispatch) => {
    dispatch(profileLoading());
    try {
      const response = await updateProfileService(formData, adminToken);
      dispatch(setProfileList(response?.data));
      return response;
    } catch (error) {
      dispatch(profileError(error.message));
      return error.response.data.error.message;
    }
  };
