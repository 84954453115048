import axios from "axios";

export const getProfileService = async (adminToken) => {
  return await axios
    .get(`${process.env.REACT_APP_BASE_URL}/api/auth/me`, {
      headers: {
        Authorization: adminToken,
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(`Unable to fetch My Data: ${error.message}`);
    });
};

export const updateProfileService = async (formData, adminToken) => {
  return await axios.put(
    `${process.env.REACT_APP_BASE_URL}/api/user/profile`,
    formData,
    {
      headers: {
        Authorization: adminToken,
      },
    }
  );
};
