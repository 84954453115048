import { Box, Tab, Tabs, Typography } from "@mui/material";
import { useState } from "react";

export default function TabsComponent({ data, onTabChange }) {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    onTabChange(newValue);
  };

  return (
    <Tabs
      value={value}
      onChange={handleChange}
      variant="scrollable"
      allowScrollButtonsMobile
      sx={{
        display: { xs: "none", md: "flex" },
        "& .MuiTabs-indicator": {
          backgroundColor: "#25AAE1",
        },
        "& .MuiTab-root.Mui-selected": {
          color: "#000000",
        },
        "& .MuiTab-root": {
          color: "#616161",
          transition: "color 0.3s ease-in-out",
          "&:hover": {
            color: "#25AAE1",
          },
        },
        "& .MuiTabs-scrollButtons": {
          color: "#000",
        },
      }}
    >
      {data &&
        data?.map((item, index) => (
          <Tab
            key={index}
            label={
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography variant="body1" sx={{ fontWeight: "700" }}>
                  {item}
                </Typography>
              </Box>
            }
          />
        ))}
    </Tabs>
  );
}
