import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  loading: false,
  error: null,
  success: null,
};

const registerSlice = createSlice({
  name: "register",
  initialState,
  reducers: {
    registerLoading(state) {
      state.loading = true;
      state.error = null;
    },
    registerError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    registerSuccess(state, action) {
      state.loading = false;
      state.success = action.payload;
    },
    setRegisterList(state, action) {
      state.loading = false;
      state.data = action.payload;
    },
  },
});

export const {
  registerLoading,
  registerError,
  registerSuccess,
  setRegisterList,
} = registerSlice.actions;

export default registerSlice.reducer;
