import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const dashboardBuildingSlice = createSlice({
  name: "dashboardBuilding",
  initialState,
  reducers: {
    buildingLoading(state) {
      state.loading = true;
      state.error = null;
    },
    buildingError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    setBuildingList(state, action) {
      state.loading = false;
      state.data = action.payload;
    },
  },
});

export const { buildingLoading, buildingError, setBuildingList } =
  dashboardBuildingSlice.actions;

export default dashboardBuildingSlice.reducer;
