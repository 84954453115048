import { createSlice } from "@reduxjs/toolkit";

const initialCertificateState = {
  data: [],
  loading: false,
  error: null,
  success: null,
};

const initialStatsState = {
  data: [],
  loading: false,
  error: null,
  success: null,
};

const initialState = {
  data: [],
  loading: false,
  error: null,
  success: null,
  initialStatsState,
  initialCertificateState,
};

const dashboardUsersSlice = createSlice({
  name: "dashboardUsers",
  initialState,
  reducers: {
    usersLoading(state) {
      state.loading = true;
      state.error = null;
    },
    usersError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    usersSuccess(state, action) {
      state.loading = false;
      state.success = action.payload;
    },
    setUsersList(state, action) {
      state.loading = false;
      state.data = action.payload;
    },
    deleteUser(state, action) {
      state.loading = false;
      state.data.data = state?.data?.data?.filter(
        (e) => e?.id !== action.payload
      );
    },
    usersStatsLoading(state) {
      state.initialStatsState.loading = true;
      state.initialStatsState.error = null;
    },
    usersStatsError(state, action) {
      state.initialStatsState.loading = false;
      state.initialStatsState.error = action.payload;
    },
    usersStatsSuccess(state, action) {
      state.initialStatsState.loading = false;
      state.initialStatsState.success = action.payload;
    },
    setUsersStatsList(state, action) {
      state.initialStatsState.loading = false;
      state.initialStatsState.data = action.payload;
    },
    certificateLoading(state) {
      state.initialCertificateState.loading = true;
      state.initialCertificateState.error = null;
    },
    certificateError(state, action) {
      state.initialCertificateState.loading = false;
      state.initialCertificateState.error = action.payload;
    },
    certificateSuccess(state, action) {
      state.initialCertificateState.loading = false;
      state.initialCertificateState.success = action.payload;
    },
    setCertificateList(state, action) {
      state.initialCertificateState.loading = false;
      state.initialCertificateState.data = action.payload;
    },
  },
});

export const {
  usersLoading,
  usersError,
  usersSuccess,
  setUsersList,
  usersStatsLoading,
  usersStatsError,
  usersStatsSuccess,
  setUsersStatsList,
  certificateLoading,
  certificateError,
  certificateSuccess,
  setCertificateList,
  deleteUser,
} = dashboardUsersSlice.actions;

export default dashboardUsersSlice.reducer;
