import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  loading: false,
  error: null,
  success: null,
  adminToken: null,
};

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    loginLoading(state) {
      state.loading = true;
      state.error = null;
    },
    loginError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    loginSuccess(state, action) {
      state.loading = false;
      state.success = action.payload;
    },
    setLoginList(state, action) {
      state.loading = false;
      state.data = action.payload;
    },
    setAdminToken: (state, action) => {
      state.adminToken = action.payload;
    },
  },
});

export const {
  loginLoading,
  loginError,
  loginSuccess,
  setLoginList,
  setAdminToken,
} = loginSlice.actions;

export default loginSlice.reducer;
